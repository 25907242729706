import { IconEdit } from '@tabler/icons-react'
import styles from './trip-name.module.scss'
import { useEffect, useRef, useState } from 'react'
import dashboardStore from '../../dashboard-store'
import { httpsCallable } from 'firebase/functions'
import firebaseFunctions from '../../../../firebase/firebase-functions'

const TripName = () => {
    const {tripId, tripData} = dashboardStore()
    const [editingName, setEditingName] = useState(false)
    const [tripName, setTripName] = useState('')
    const [tripNameCopy, setTripNameCopy] = useState('')
    const [nameRequiredWarning, setNameRequiredWarning] = useState(false)
    const inputRef = useRef(null)

    useEffect(() => {
        if (tripData){
            setTripName(tripData.tripOpts.name)
            setTripNameCopy(tripData.tripOpts.name)
        }
    }, [tripData])

    useEffect(() => {
        if (editingName && inputRef.current) {
            inputRef.current.focus()
        }
    }, [editingName])

    const updateTripOptions = httpsCallable(firebaseFunctions, 'updateTripOptions')

    const onEditClick = () => {
        setEditingName(true)
        setTripNameCopy(tripName)
    }

    const onTripNameChange = (e) => {
        setTripNameCopy(e.target.value)
        if (e.target.value) {
            setNameRequiredWarning(false)
        }
    }

    const saveTripName = async () => {
        if (tripNameCopy.trim()) {
            setNameRequiredWarning(false)
            if (tripNameCopy !== tripName){
                setTripName(tripNameCopy)
                await updateTripOptions({
                    tripId,
                    options: {'name': tripNameCopy}
                })
            }
            setEditingName(false)
        } else {
            setTripNameCopy(tripName)
            setNameRequiredWarning(true)
        }
    }

    const onTripNameBlur = async () => {
        await saveTripName()
        if (!tripNameCopy.trim()){
            setTimeout(() => {
                setNameRequiredWarning(false)
                setEditingName(false)
            }, 2000)
        }
    }

    const onTripNameKeyDown = async(e) => {
        if (e.key === 'Enter'){
            await saveTripName()
        }
    }

    return (
        <div className={styles.tripNameContainer}>
            {editingName ?
                <input 
                    ref={inputRef}
                    className={`${styles.tripNameInput}${nameRequiredWarning ? ` ${styles.nameRequiredWarning}` : ''}`}
                    type="text" 
                    value={tripNameCopy} 
                    onBlur={onTripNameBlur} 
                    onChange={onTripNameChange} 
                    onKeyDown={onTripNameKeyDown}
                    placeholder="Trip Name (required)"
                />
                :
                <>
                    <span className={styles.tripName}>{tripName}</span>
                    <button onClick={onEditClick} className={styles.tripNameEdit}>
                        <IconEdit size={30} />
                    </button>
                </>
            }
        </div>
    )
}

export default TripName